import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/commercetools-ui/atoms/link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/commercetools-ui/atoms/typography/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/commercetools-ui/organisms/footer/atoms/copyright.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/commercetools-ui/organisms/pay-now/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/commercetools-ui/organisms/thank-you/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/commercetools-ui/organisms/trustpilot/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/headless/GASnippet/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/learner/organisms/content/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/sales-link/organisms/content/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/components/sales-link/organisms/thank-you/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/lib/image/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/frontastic/tastics/account/details/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/frontastic/tastics/account/login/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/frontastic/tastics/account/register/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/frontastic/tastics/cart/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/frontastic/tastics/checkout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/content-slider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/content/hero/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/content/tile/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/content/tiles-group/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/footer/simple/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/header/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/frontastic/tastics/markdown/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/not-found/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/frontastic/tastics/products/product-list/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/products/slider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/sales-link/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/sales-link/payment/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/frontastic/tastics/spacer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/shared/frontend/helpers/redirect.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/shared/frontend/providers/index.tsx");
